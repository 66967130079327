import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { TermsMeta } from "../data/seo-meta"

const titleClass = "p1 font-semibold mb-6"
const contentClass = "p2 mb-6"
const contentClassLast = "p2 mb-32"
const linkClass = "underline"

const TermsOfUsePage = () => (
  <Layout>
    <SEO
      description={TermsMeta.description}
      path={TermsMeta.path}
      title={TermsMeta.title}
    />
    <div className="container py-20 md:py-24">
      <h1 className="h1 mb-32">Terms of Use</h1>
      <p className="p1 font-semibold mb-32">(Effective May 2024)</p>
      {/** Introduction and Definitions */}
      <h2 className={titleClass}>1. Introduction and Definitions</h2>
      <p className={contentClass}>
        Welcome to Slice! We hope you will enjoy and appreciate using our <strong>“Services”</strong>, which may
        be: (1) visiting or using our website at&nbsp;
        <Link className={linkClass} to="/">
          https://sliceinsurance.com
        </Link>
        &nbsp;or other websites controlled by us (collectively <strong>“Websites”</strong>), and any subpages or 
        subdomains thereof; or (2) accessing and using the <strong>“Platform”</strong> available at&nbsp;
        <a
          className={linkClass}
          href="https://app.sliceinsurance.com"
          rel="nofollow noreferrer"
          target="_blank"
        >
          https://app.sliceinsurance.com
        </a>
        &nbsp;that allows you, if you are an Agent, to receive, save, retrieve, and 
        bind quotes for various insurance products.
      </p>
      <p className={contentClass}>
      These Terms of Use (the <strong>“TOU”</strong>) cover a variety of topics, so please refer to the following
      definitions for clarification:
      </p>
      <p className={contentClass}>
        <strong>“Agent”</strong> refers to an insurance agent who has been authorized to use the Platform. In these
        TOU, an Agent may be referred to as <strong>“you”</strong>. When you have created an account and are
        logged in, you may be referred to as a <strong>“Logged-in User”</strong>;
      </p>
      <p className={contentClass}>
        <strong>“Customer”</strong> refers to any customer or potential customer of an Agent;
      </p>
      <p className={contentClass}>
        <strong>“Customer Data”</strong> means any and all information regarding a Customer or a Customer’s
        business, or any other data provided by a Customer to an Agent, that is inputted into the
        Platform by an Agent;
      </p>
      <p className={contentClass}>
        <strong>“Distributor”</strong> means an insurance company who distributes insurance and with whom an
        Agent may have a contractual relationship;
      </p>
      <p className={contentClass}>
        <strong>“Platform”</strong> in these TOU means those portions of the Slice Insurance Cloud Services (ICS)
        Platform (the software platform developed and owned by Slice for the sale and
        management of on-demand, subscription-based, or other revenue model insurance, and
        related functionalities) accessible by an Agent. The Platform is accessed at the URL&nbsp;
        <a
          className={linkClass}
          href="https://app.sliceinsurance.com"
          rel="nofollow noreferrer"
          target="_blank"
        >
          https://app.sliceinsurance.com
        </a>
        .
      </p>
      <p className={contentClass}>
        <strong>“Slice”</strong> refers to Slice Insurance Technologies Inc., a duly incorporated company in the
        State of Delaware, USA, and all of its affiliates, subsidiaries and parent companies. Where
        the present TOU refers to <strong>“Slice”</strong>, it may refer to Slice or its shareholders, officers,
        directors, employees, agents, partners, principals, representatives, successors and assigns
        (collectively <strong>“Representatives”</strong>), depending on the context. Any reference to <strong>“we”</strong>, <strong>“our”</strong>,
        or <strong>“us”</strong> in these TOU shall also refer to Slice. In these TOU, a Website visitor or Platform
        user may be referred to as <strong>“you”</strong>.
      </p>
      <p className={contentClassLast}>
        Additional definitions shall be made throughout these TOU, but they will be recognizable as
        they will be capitalized, bolded, and in quotation marks.
      </p>
      {/** Acceptance and Modifications */}
      <h2 className={titleClass}>2. Acceptance and Modifications</h2>
      <p className={contentClass}>
        <strong>
          <em>
            By visiting a Website, by clicking or tapping the acceptance box upon signing up for an
            account on the Platform, or by otherwise using or interacting with the Services in any
            way, you hereby accept to be bound by these TOU without any reservations,
            modifications, additions or deletions. If you do not agree to all the provisions contained
            in the TOU, you are not authorized to use the Services. The TOU are a legal and binding
            agreement between you and us.
          </em>
        </strong>
      </p>
      <p className={contentClass}>
        <strong>
          <em>
            If you are an individual using the Services on behalf of a corporation, partnership,
            association or other registered organization (“Legal Entity”), you represent and warrant
            that you have the capacity and authority to enter into the TOU on behalf of the Legal
            Entity, and hereby agree to be bound by the terms of the TOU on behalf of that Legal
            Entity. In such case, any reference to “you” in these TOU may also refer to the Legal
            Entity, as applicable.
          </em>
        </strong>
      </p>
      <p className={contentClass}>
        <strong>
          THESE TOU CONTAIN A WAIVER OF JURY TRIAL AND A WAIVER OF CLASS ACTION THAT
          MAY DIMINISH OR AFFECT YOUR LEGAL RIGHTS. PLEASE READ THESE WAIVERS, AS
          FOUND IN SECTION 15, CAREFULLY.
        </strong>
      </p>
      <p className={contentClass}>
        Slice reserves the right, at any time and without prior notice, to modify or replace any of
        the TOU. Any changes to the TOU can be found at this URL. It is your responsibility to check
        the TOU periodically for changes. Your use of the Services following the posting of any
        changes to the TOU constitutes acceptance of those changes. If there are any significant
        changes to the TOU that materially affect your relationship with us, we will use
        commercially reasonable efforts to notify you by sending a notice to the primary email
        address specified in your account if you are an Agent, by posting a prominent notice when
        you log in to your account for the first time following those changes, and/or by posting a
        prominent notice upon your first visit to the Services following those changes.&nbsp;
        <strong>
          Your use of the Services following your being notified of any changes to the TOU constitutes
          acceptance of those changes.
        </strong>
      </p>
      <p className={contentClassLast}>
        The TOU should be read in conjunction with the&nbsp;
        <Link className={linkClass} to="/privacy-policy/">
          Privacy Policy
        </Link>
        , as both these documents govern your use of the Services.
      </p>
      {/** Contacting Us */}
      <h2 className={titleClass}>3. Contacting Us</h2>
      <p className={contentClass}>
        If you have any questions about the TOU, please contact:
      </p>
      <p className={contentClass}>
        Slice Legal Services
        <br />
        <a className={linkClass} href="mailto:legal@sliceinsurance.com">
          legal@sliceinsurance.com
        </a>
      </p>
      <p className={contentClass}>or:</p>
      <p className={contentClass}>
        Slice Legal Services<br />
        33 Irving Place, Suite 7116<br />
        New York, NY 10003<br />
        USA
      </p>
      <p className={contentClassLast}>
        If you have any general questions regarding the Services or your use of the Services, please
        contact us at&nbsp;
        <a className={linkClass} href="mailto:helpme@sliceinsurance.com">
          helpme@sliceinsurance.com
        </a>
        .
      </p>
      {/** General Code of Conduct */}
      <h2 className={titleClass}>
        4. General Code of Conduct for Use of the Website or Services
      </h2>
      <p className={contentClass}>
        In addition to the more specific rules found elsewhere in these TOU, you agree that by
        interacting with the Services in any way, including but not limited to signing up for an
        account and using the Platform, you will:
      </p>
      <p className={contentClass}>
        (i) Not use the Services in any manner that in any way violates these TOU or any other
        applicable policy posted by Slice;
      </p>
      <p className={contentClass}>
        (ii) Not use the Services in any manner that violates any intellectual property rights of Slice
        or any third party;
      </p>
      <p className={contentClass}>
        (iii) Not use the Services in any manner to propagate spam, including but not limited to
        unsolicited advertising or bulk electronic mail or messages, including to link to a spam
        or phishing website;
      </p>
      <p className={contentClass}>
        (iv) Not use the Services in any manner to propagate software viruses, Trojan horses,
        worms, or any other malicious or non-malicious computer code, files, or programs that
        are designed or intended to disrupt, damage, limit or interfere with the proper function
        of any software, hardware, or telecommunications equipment in any form whether
        belonging to Slice or a third party, or to damage or obtain unauthorized access to any
        system, data, or other information (whether personal data or not) of Slice, other
        Services users, or any other third party;
      </p>
      <p className={contentClass}>
        (v) Not: (1) take any action that imposes or may impose (as determined by Slice in its sole
        discretion) an unreasonable or disproportionately large load on Slice’s (or its third
        party providers’) infrastructures; (2) interfere or attempt to interfere with the proper
        functioning of the Services or any activities conducted through the Services; (3) bypass
        any measures Slice may use to prevent or restrict access to the Services or any element
        thereof; (4) use manual or automated software, devices, or other processes to “crawl”
        or “spider” any page of the Services; or (5) harvest or scrape any content from the
        Services in an unreasonable manner;
      </p>
      <p className={contentClass}>
        (vi) Not use the Services to advertise or promote websites or services that are not
        expressly approved in advance in writing by Slice, or as permitted by these TOU;
      </p>
      <p className={contentClass}>
        (vii) Not use the Services to in any way collect information (whether personal data or not) of
        any third party or in violation of Slice’s Privacy Policy;
      </p>
      <p className={contentClass}>
        (viii) Not interfere with any third party’s use of the Services;
      </p>
      <p className={contentClass}>
        (ix) Not encourage conduct that would constitute a criminal offense or give rise to civil
        liability;
      </p>
      <p className={contentClass}>
        (x) Not attempt to do any of the foregoing prohibitions;
      </p>
      <p className={contentClassLast}>
        (xi) Use the Services in good faith, and in compliance with all applicable local, provincial,
        state, national, and international laws.
      </p>
      {/** Use of the Application */}
      <h2 className={titleClass}>5. Use of the Platform - Accounts, Passwords and Account Information</h2>
      <p className={contentClass}>
        <strong>a. Accounts and Passwords</strong>< br />
        Except as provided for in the next paragraph, in order to be able to use the Platform, you
        will be required to create an account. Accounts are available to anyone who provides the
        requisite information as described below, subject to the restrictions and conditions as
        outlined elsewhere in these TOU, and subject to approval by Slice and / or the Distributor.
        Slice, in conjunction with the Distributor, retains absolute discretion to determine who may
        have an account on the Platform. Without limiting the generality of the Disclaimer of
        Warranties further in these TOU, Slice and / or its Representatives shall not be responsible
        for any losses or damages, pecuniary or otherwise, to you resulting from the refusal to give
        you an account.
      </p>
      <p className={contentClass}>
        In certain circumstances, an account may have been created for you by the Distributor, or
        the Platform will be accessible through the Distributor’s existing online infrastructure for
        which you already have access (<strong>“Single Sign-On”</strong>). Notwithstanding such access, you are
        still responsible for your use of the Platform and the obligations in these TOU as if you
        created an account yourself.
      </p>
      <p className={contentClass}>
        When creating an account yourself, as part of the account registration, you will be asked to
        choose a password. It is your responsibility to create a password of sufficient strength that
        cannot easily be discovered by third parties, and that meets the requirements as presented
        upon signing up. You are responsible for maintaining the confidentiality of your password,
        and are responsible for all activities that occur under your account whether by you or any
        third party. You agree to immediately notify us of any unauthorized use of your account or
        any other breach of security regarding your account.
      </p>
      <p className={contentClass}>
        If you are a Logged-in User, it is strongly suggested that you log out of your account at the
        end of every session, or not leave a logged-in account unattended for any period of time.
        Slice and its Representatives will not be held liable for any losses or damages, direct or
        indirect, pecuniary or non-pecuniary, resulting from your failure to log out at the end of the
        session, an unattended logged-in session, or a third party using the Platform with your
        Account Information and accessing your account through any means, and disclaims any
        responsibility in this regard.
      </p>
      <p className={contentClass}>
        Slice reserves the right to suspend or terminate your account, at its sole discretion, at any
        time and for any reason, including but not limited to whether you have violated the letter
        or spirit of the TOU, as more fully described hereinbelow.
      </p>
      <p className={contentClass}>
        <strong>b. Account Information</strong>< br />
        The information required to create an account to begin using the Platform (except in cases
        of Single Sign-On) is the following: your first and last name, agency name, agent id, phone
        number, and your email address. This information you submit as part of the sign-up
        process may be referred to in the present TOU as <strong>“Account Information”</strong>.
      </p>
      <p className={contentClassLast}>
        By submitting Account Information, you represent and warrant that the information is true
        and accurate to the best of your knowledge. Submitting false or misleading Account
        Information may result in you being banned from using the Platform, at our sole discretion.
      </p>
      {/** Agent Representation */}
      <h2 className={titleClass}>
        6. Agent’s Representations and Warranties
      </h2>
      <p className={contentClass}>
        <strong>a. Customer Data Representations and Warranties</strong>< br />
        When you, as an Agent, submit Customer Data through the Platform you represent and
        warrant that:
      </p>
      <ul className={`${contentClass} list-disc list-inside `}>
        <li className="mb-6">
          The Customer Data you submit is true, accurate and complete to the best of your
          knowledge;
        </li>
        <li className="mb-6">
          You have sought and received the necessary permission in writing from the
          Customer to submit their Customer Data to Slice;
        </li>
        <li className="mb-6">
          You have informed the Customer that their Customer Data will be transferred to the
          Distributor;
        </li>
        <li className="mb-6">
          You have informed the Customer that Slice and / or the Distributor will be
          contacting them with any contact information provided (such as phone number,
          email address and mailing address) in order to facilitate the purchase of insurance
          or any other matter related to the Platform;
        </li>
        <li className="mb-6">
          When you submit Customer Data that includes payment information (such as credit
          card information) for the purchase of insurance through the Platform, you shall
          inform the Customer that such payment information will be used by Slice for the
          purchase of insurance and payment of fees to Slice;
        </li>
        <li className="mb-6">
          Where Customer Data includes personal information or personal data as defined in
          applicable privacy laws, you have adhered to any applicable privacy laws; and
          You shall keep detailed records regarding all of the foregoing (<strong>“Documentation”</strong>).
        </li>
      </ul>
      <p className={contentClass}>
        Slice reserves the right to request any Documentation from you at any time. In the event
        you have not kept the appropriate Documentation as determined by Slice, Slice may
        terminate your access to the Platform at its sole discretion.
      </p>
      <p className={contentClass}>
        <strong>b. Other Representations and Warranties</strong>< br />
        By using the Platform, you represent and warrant that:
      </p>
      <ul className={`${contentClassLast} list-disc list-inside `}>
        <li className="mb-6">
          You are authorized and licensed to sell and / or process insurance business in the
          state(s) in which you operate; and
        </li>
        <li className="mb-6">
          The licenses required to sell and / or process insurance business in the state(s) in
          which you operate are active, up to date and in good standing.
        </li>
      </ul>
      {/** Confidential Information */}
      <h2 className={titleClass}>
        7. Confidential Information
      </h2>
      <p className={contentClass}>
        For the purposes of these TOU, <strong>“Confidential Information”</strong> shall be defined as all
        information however received by a party and in whatever form, which pertains to a party, a
        Customer or a Distributor, or to any of their related entities or affiliates, the subject of
        which concerns information on the party’s strategic plans, partnership agreements,
        policies, information of a technical nature, information of an administrative or financial
        nature such as information on costs, profits, markets, sales, plans for future developments,
        customers, advertising, members and suppliers lists, as well as any other information of a
        similar nature not in the public domain. Personal information or personal data as defined
        in applicable privacy laws shall also be Confidential Information. Any documents or work
        assembled or composed by a party containing Confidential Information shall constitute
        Confidential Information within the meaning of this Agreement and be treated as such.
      </p>
      <p className={contentClass}>
        Unless otherwise required by law or expressly authorized in writing by the other party,
        neither you or Slice arty shall, in perpetuity, either directly or indirectly, in any capacity
        whatsoever, divulge, disclose or communicate any Confidential Information to any person,
        entity, firm who is not a party to these TOU, or any other third party (unless as required to
        perform their obligations under these TOU), or utilize or exploit any Confidential
        Information for the benefit of itself or any third-party, or to the detriment of a party to
        these TOU. The parties shall use the Confidential Information of the other party solely for
        the performance of its obligations under these TOU.
      </p>
      <p className={contentClassLast}>
        The obligations of non-disclosure and non-use as described in the previous paragraph shall
        continue in perpetuity following the termination of these TOU for any reason, unless the
        information ceases to be Confidential Information.
      </p>
      {/** Proprietary and Intellectual Property Rights */}
      <h2 className={titleClass}>
        8. Proprietary and Intellectual Property Rights
      </h2>
      <p className={contentClass}>
        <strong>a. Slice’s Rights</strong>< br />
        You acknowledge that: (a) the Services contain proprietary and confidential information
        that is protected by applicable intellectual property and other laws, and (b) Slice and/or
        third parties own all right, title and interest in and to the Services and content that may be
        presented or accessed through the Services (except for Customer Data), including without
        limitation all Intellectual Property Rights therein and thereto. All rights not specifically
        granted under these TOU are reserved to Slice and its licensors. <strong>“Intellectual Property
        Rights”</strong> means any and all rights existing from time to time under patent law, copyright
        law, trade secret law, trademark law, unfair competition law, and any and all other
        proprietary rights, whether registered or not, and any and all applications, renewals,
        extensions and restorations thereof, now or hereafter in force and effect worldwide.
      </p>
      <p className={contentClass}>
        You agree that you will not, and will not allow any third party, to (i) copy, sell, license,
        distribute, transfer, modify, adapt, translate, prepare derivative works from, decompile,
        reverse engineer, disassemble or otherwise attempt to derive source code from, the
        Services, or content that may be presented or accessed through the Services (except
        Customer Data) for any purpose, unless otherwise permitted by these TOU or the
        functionalities of the Services; (ii) take any action to circumvent or defeat the security
        deployed or enforced by any functionality contained in the Services; or (iii) remove,
        obscure, or alter Slice’s or any third party’s copyright notices, trademarks, or other
        proprietary rights notices affixed to or contained within or accessed in conjunction with or
        through the Services.
      </p>
      <p className={contentClass}>
        The content, arrangement and layout of the Services, including but not limited to the
        trademarks, photos, logos, videos, audio, images, text (in the form of plain text, HTML, or
        PDFs) and Computer Code are proprietary to Slice or the Distributor, either owned or
        under license, and may not be copied, imitated, reproduced, displayed, distributed,
        transmitted, decompiled or otherwise used without the express permission of Slice, or as
        permitted by the functionality of the Services or these TOU. Any unauthorized use of the
        content, arrangement or layout of the Services, Computer Code, images, logos, videos,
        audio or trademarks found on the Services or any portion of or derivative works thereof
        may violate civil or criminal laws, including but not limited to intellectual property laws, and
        Slice may take action accordingly.
      </p>
      <p className={contentClass}>
        The above paragraph further applies to third party property used as part of the Services,
        including but not limited to third party Computer Code. For the purposes of the present
        section, <strong>“Computer Code”</strong> includes but is not limited to source code in any programming
        language, object code, frameworks, CSS, PHP, JavaScript or similar files, templates,
        modules, or any similar files, and related documentation.
      </p>
      <p className={contentClassLast}>
        <strong>b. Submitted Information</strong>< br />
        If you choose to communicate to Slice (by any means, including but not limited to by email)
        suggestions for improvements to the Services or any idea or proposal related to Slice or its
        businesses or properties generally (collectively, <strong>“Feedback”</strong>), Slice shall own all right, title,
        and interest in and to the Feedback and will be entitled to use the Feedback without
        restriction. You hereby irrevocably assign all right, title and interest in and to the Feedback
        to Slice and waive in favor of Slice, its successors and assigns all your moral rights in the
        Feedback, and agree to provide Slice such assistance as Slice may require to document,
        perfect, and maintain Slice’s rights to the Feedback. You acknowledge and agree that, by
        providing any Feedback to Slice, you are not entitled to any compensation or
        reimbursement of any kind from Slice under any circumstances.
      </p>
      {/** Interruption of the Services */}
      <h2 className={titleClass}>
        9. Interruption of the Services
      </h2>
      <p className={contentClassLast}>
        From time to time, the Services may be unavailable for brief periods of time for
        maintenance and / or modifications to the Services. While we will endeavour to make this
        unavailability as brief as possible, Slice and its Representatives shall not be held liable for
        any losses or damages, direct or indirect, pecuniary or non-pecuniary, resulting from the
        interruption of the normal functioning of the Services, and disclaims any responsibility
        thereto.
      </p>
      {/** Termination of the Services and the TOU */}
      <h2 className={titleClass}>
        10. Termination of the Services and the TOU
      </h2>
      <p className={contentClass}>
        You agree that Slice, in their sole discretion, with or without cause, has the right (but not
        the obligation) to block your IP address, revoke your account credentials, or otherwise
        terminate your access to or use of the Services (or any part thereof), immediately and
        without notice, for any reason, including, without limitation, if Slice believes that you have
        acted inconsistently with the letter or spirit of the TOU.
      </p>
      <p className={contentClass}>
        Slice may also, in their sole discretion and at any time, discontinue providing the Services,
        or any part thereof, with or without notice. You agree that Slice shall not be liable to you or
        any third party for any losses or damages, pecuniary or non-pecuniary, resulting from
        termination of your access to the Services, or from Slice’s termination of the Services or any
        part thereof.
      </p>
      <p className={contentClass}>
        You may also choose to terminate your access to the Services by cancelling your account if
        you are an Agent. If you wish to cancel your account, you can do so through the Platform
        when you are a Logged-in User.
      </p>
      <p className={contentClassLast}>
        Termination of the Services or your access to the Services shall terminate the present TOU
        as between you and Slice. All provisions of these TOU which by their nature should survive
        termination of these TOU shall survive termination, including without limitation, intellectual
        property provisions, disclaimers, indemnity and limitations of liability.
      </p>
      {/** External Links */}
      <h2 className={titleClass}>11. External Links</h2>
      <p className={contentClass}>
        From time to time Slice may provide links to other websites or services. Links from the
        Services may take you to websites or services not covered by these TOU. When you access
        third party resources on the Internet in this manner, you do so at your own risk. Slice
        provides those links as a convenience to you and Slice takes no responsibility for your use
        of those other websites or services or protection of your privacy (including collection of
        your personal information) on those other websites or services. We encourage you to
        check the Terms of Use and / or Privacy Policy of any website or service you visit. Slice does
        not make any claim or warranty whatsoever about the content of those websites or
        services to which we link, or any products or services available through those websites or
        services or the third parties operating those websites or services.
      </p>
      <p className={contentClassLast}>
        In no way will Slice or its Representatives be held responsible for any damages, direct or
        indirect, pecuniary or non-pecuniary: (1) for your use of websites or other services that may
        be linked to from the Services or the information thereon; (2) for any virus, Trojan horse,
        worm or other similar destructive file received as a result of your use of those websites or
        services; (3) caused by or in connection with, use of or reliance upon on any content, or
        products or services (whether free or for purchase) available on or through any linked-to
        website or service; or (4) for the actions of the operators of any such website or service.
      </p>
      {/** Disclaimer of Warranties */}
      <h2 className={titleClass}>12. Disclaimer of Warranties</h2>
      <p className={contentClass}>
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES, THE
        INFORMATION OR MATERIAL THEREON (WHETHER PROVIDED BY SLICE OR THIRD PARTIES)
        OR ANY ACTIVITY ARISING FROM YOUR USE OF THE PLATFORM OR THE INFORMATION
        THEREON OR THE MATERIALS STREAMED OR DOWNLOADED THEREFROM IS AT YOUR SOLE
        RISK. THE PLATFORM, ANY MATERIALS DOWNLOADED OR STREAMED THEREFROM, OR ANY
        THIRD PARTY MATERIALS, ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, AND
        YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER OR DEVICE OR
        LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD, STREAM OR ACCESS OF ANY
        MATERIAL OBTAINED THROUGH THE USE OF THE SERVICES OR ANY OTHER
        FUNCTIONALITIES OF THE SERVICES, OR LOSSES OR DAMAGES (PECUNIARY OR
        OTHERWISE) RESULTING FROM (I) YOUR USE OF THE SERVICES, THE INFORMATION
        THEREON, ANY MATERIALS DOWNLOADED OR STREAMED THEREFROM, OR (II) ANY
        ACTIVITY ARISING FROM THE USE OF THE SERVICES OR THE INFORMATION OR ANY
        MATERIALS DOWNLOADED OR STREAMED THEREFROM.
      </p>
      <p className={contentClass}>
        THE INFORMATION OR RESOURCES PROVIDED THROUGH THE SERVICES, WRITTEN OR
        PRODUCED BY SLICE STAFF, FREELANCE WRITERS OR OTHER SUBCONTRACTORS ARE
        KNOWN TO BE AS ACCURATE AS POSSIBLE AT THE TIME OF WRITING OR PRODUCTION,
        AND EVERY EFFORT HAS BEEN MADE TO ENSURE THAT THE INFORMATION FROM THE
        SERVICES IS AS ACCURATE AND UP-TO-DATE AS POSSIBLE. HOWEVER, CERTAIN
        INFORMATION MAY CHANGE, AND ERRORS OR OMISSIONS MAY OCCUR, AND SLICE SHALL
        NOT BE RESPONSIBLE FOR ANY LOSS OR DAMAGE, FINANCIAL OR OTHERWISE, RESULTING
        FROM CHANGES OR ERRORS IN INFORMATION, OR ANY OMISSION, ON THE SERVICES OR
        THROUGH THE SERVICES.
      </p>
      <p className={contentClass}>
        SLICE EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
        IMPLIED, INCLUDING BUT NOT LIMITED TO: WARRANTIES OF TITLE AND NON-
        INFRINGEMENT; WARRANTIES THAT THE SERVICES, THE INFORMATION THEREON OR ANY
        MATERIALS DOWNLOADED OR STREAMED THEREFROM, AND ANY THIRD-PARTY MATERIALS
        WILL BE UNINTERRUPTED, ERROR-FREE, ACCURATE, RELIABLE AND FREE FROM VIRUS AND
        OTHER HARMFUL COMPONENTS; AND THE IMPLIED WARRANTIES OF MERCHANTABILITY
        AND FITNESS FOR A PARTICULAR PURPOSE. SLICE, AND ITS REPRESENTATIVES DO NOT
        WARRANT THAT: (I) THE SERVICES WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR
        TIME OR LOCATION; (II) ANY DEFECTS OR ERRORS OR OMISSIONS WILL BE CORRECTED; (III)
        ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE SERVICES IS FREE OF
        VIRUSES OR OTHER HARMFUL COMPONENTS; OR (IV) THE RESULTS OF USING THE
        SERVICES, OR ANY ACTIVITY ARISING THEREFROM OR ANY CONTENT DOWNLOADED OR
        STREAMED THEREFROM WILL MEET YOUR REQUIREMENTS.
      </p>
      <p className={contentClass}>
        SOME OF THE CONTENT DISPLAYED ON OR THROUGH THE SERVICES MAY INCLUDE
        MATERIALS (INCLUDING WITH RESPECT TO PRODUCTS OR SERVICES) THAT BELONG TO OR
        ARE PROVIDED BY THIRD PARTIES. YOU ACKNOWLEDGE THAT WE ASSUME NO
        RESPONSIBILITY FOR SUCH CONTENT, PRODUCTS AND/OR SERVICES.
      </p>
      <p className={contentClassLast}>
        TO THE EXTENT THAT THE LAW DOES NOT PERMIT A DISCLAIMER OF WARRANTIES, ALL
        CONTENT ACCESSIBLE ON OR THROUGH THE SERVICES, OR ANY OTHER WEBSITE TO
        WHICH WE LINK, AND ALL OPERATIONS ON THE SERVICES ARE WARRANTED ONLY TO THE
        MINIMUM AMOUNT LEGALLY REQUIRED.
      </p>
      {/** Limitation of Liability */}
      <h2 className={titleClass}>13. Limitation of Liability</h2>
      <p className={contentClass}>
        IN NO CASE WILL SLICE OR ITS REPRESENTATIVES BE LIABLE FOR ANY INDIRECT, SPECIAL,
        CONSEQUENTIAL, EXEMPLARY, PUNITIVE DAMAGES OR OTHER DAMAGES, OR FOR ANY
        LOSSES, DAMAGES, LIABILITIES, COSTS AND EXPENSES ARISING OUT OF OR RELATING TO (I)
        YOUR ACCESS, USE, MISUSE OR INABILITY TO ACCESS OR USE THE SERVICES, OR (II) THE
        INTERRUPTION, SUSPENSION OR TERMINATION OF ANY PART OF OR ALL THE SERVICES;
        AND IN BOTH CASES (I) AND (II) REGARDLESS OF THE CAUSE OF ACTION (WHETHER IN
        CONTRACT, WARRANTY, DELICT, QUASI-DELICT, TORT, NEGLIGENCE, STRICT LIABILITY OR
        ANY OTHER THEORY OF LIABILITY) AND EVEN IF WE HAVE BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES.
      </p>
      <p className={contentClass}>
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT SLICE OR ITS REPRESENTATIVES SHALL
        NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
        PUNITIVE OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR
        LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, IN CONTRACT,
        TORT, STRICT LIABILITY, NEGLIGENCE, GENERAL CIVIL LIABILITY OR ANY OTHER CAUSE OF
        ACTION UNDER LEGAL OR EQUITABLE THEORY, RELATING TO THE SERVICES, THE
        INFORMATION ON THE SERVICES, YOUR USE OF THE SERVICES, ACTIVITIES ARISING FROM
        YOUR USE OF THE SERVICES ANY THIRD PARTY MATERIALS ON THE SERVICES, OR ANY
        MATERIALS DOWNLOADED FROM THE SERVICES. THIS LIMITATION OF LIABILITY APPLIES,
        WITHOUT LIMITATION, TO ANY DAMAGES OR INJURY CAUSED BY ANY ERROR, OMISSION
        OR OTHER FAILURE OF PERFORMANCE BY SLICE OR ITS REPRESENTATIVES; ANY
        INTERRUPTION, DEFECT OR DELAY IN OPERATION OR TRANSMISSION, INCLUDING
        COMMUNICATION LINE FAILURE; ANY COMPUTER VIRUS; AND ANY THEFT, DESTRUCTION
        OR ALTERATION OF, OR UNAUTHORIZED ACCESS TO OR USE OF, ANY ELECTRONIC
        RECORDS.
      </p>
      <p className={contentClassLast}>
        SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR
        INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSIONS AND
        LIMITATIONS MAY OR MAY NOT APPLY TO YOU.
      </p>
      {/** Indemnity */}
      <h2 className={titleClass}>14. Indemnity</h2>
      <p className={contentClassLast}>
        Notwithstanding any other term of the TOU or any act or failure to act by Slice or its
        Representatives, you agree to indemnify, defend and hold harmless Slice and its
        Representatives, or its affiliates and their Representatives, from and against any damages,
        liabilities, costs and expenses (including attorneys’ fees), claims or demands, arising out of
        (i) your use of or connection to the Services or the information thereon or information or
        materials downloaded or streamed therefrom; (ii) your participation in any activities arising
        from the Services or the information thereon or information or materials downloaded or
        streamed therefrom; (iii) your violation of, or failure to perform your obligations under the
        TOU; or (iv) your violation of any rights of a third party.
      </p>
      {/** Governing Laws and Jurisdiction */}
      <h2 className={titleClass}>
        15. Governing Laws and Jurisdiction; WAIVER OF JURY TRIAL AND WAIVER OF
        CLASS ACTION
      </h2>
      <p className={contentClass}>
        The TOU shall be governed by and construed in accordance with the laws of the State of
        New York, USA, excluding any rules of private international law or the conflict of laws which
        would lead to the application of any other laws. Regardless of where you access the
        Services, you agree that any action at law or in equity arising out of or relating to the TOU
        or your use of the Services shall be filed and adjudicated only in the State or Federal Courts
        located in the State of New York, in Manhattan, and any appellate courts therefrom, and
        you hereby irrevocably and unconditionally consent and attorn to the exclusive jurisdiction
        and venue of such court over any suit, action or proceeding arising out of the TOU or your
        use of the Services. Notwithstanding the foregoing, Slice shall have the right to bring action
        against you in courts of competent jurisdiction in the jurisdiction in which you reside or are
        located: (i) to seek injunctive relief against you; (ii) to obtain a judgment against you where
        a judgment by the New York court may not be enforced by the jurisdiction in which you
        reside or are located; or (iii) to enforce a judgment obtained against you in the New York
        court.
      </p>
      <p className={contentClass}>
        You and Slice expressly disclaim applicability of the terms of the United Nations Convention
        of Contracts for the International Sale of Goods and any legislation implementing such
        Convention will not apply to these TOU, your use of the Services nor to any dispute arising
        therefrom.
      </p>
      <p className={contentClass}>
        YOU ACKNOWLEDGE AND AGREE THAT ANY DISPUTE WHICH MAY ARISE UNDER THESE
        TOU OR YOUR USE OF THE SERVICES IS LIKELY TO INVOLVE COMPLICATED AND DIFFICULT
        ISSUES, AND THEREFORE, YOU HEREBY IRREVOCABLY AND UNCONDITIONALLY WAIVE ANY
        RIGHT YOU MAY HAVE TO A TRIAL BY JURY IN RESPECT OF ANY LITIGATION DIRECTLY OR
        INDIRECTLY ARISING OUT OF OR RELATING TO THESE TOU OR YOUR USE OF THE SERVICES.
      </p>
      <p className={contentClass}>
        YOU ACKNOWLEDGE AND AGREE THAT YOU AND SLICE WILL RESOLVE ANY DISPUTES,
        CLAIMS OR CONTROVERSIES ON AN INDIVIDUAL BASIS, AND THAT ANY CLAIMS BROUGHT
        UNDER THESE TOU OR YOUR USE OF THE SERVICES WILL BE BROUGHT IN AN INDIVIDUAL
        CAPACITY, AND NOT ON BEHALF OF, OR AS PART OF, ANY PURPORTED CLASS,
        CONSOLIDATED, OR REPRESENTATIVE PROCEEDING. YOU FURTHER AGREE THAT SLICE AND
        YOU SHALL NOT PARTICIPATE IN ANY CLASS, CONSOLIDATED, OR REPRESENTATIVE
        PROCEEDING (EXISTING OR FUTURE) BROUGHT BY ANY THIRD PARTY ARISING UNDER
        THESE TOU OR IN CONNECTION WITH YOUR USE OF THE SERVICES.
      </p>
      <p className={contentClassLast}>
        If you are an Agent, the provisions of this section apply to disputes relating to the use of
        the Platform, not any activities covered by your relationship with the Distributor; such
        disputes are covered by the terms of your agreement with the Distributor.
      </p>
      {/** Miscellaneous Provisions */}
      <h2 className={titleClass}>16. Miscellaneous Provisions</h2>
      <p className={contentClass}>
        (i) The TOU and Privacy Policy constitute the entire agreement between you and Slice with
        respect to your use of the Services, superseding any prior agreements between you and
        Slice, its predecessors, affiliates or subsidiaries. Notwithstanding the foregoing, you
        acknowledge and agree that if you are an Agent, you may be subject to additional
        agreements with Slice including but not limited to a “Producer Agreement”. In the event
        of a conflict between the Producer Agreement and these TOU, the Producer Agreement
        shall prevail to the extent required to resolve the conflict.
      </p>
      <p className={contentClass}>
        (ii) Slice shall not be liable for any failure to perform its obligations under the present TOU
        where such failure results from any cause beyond Slice’s reasonable control, including,
        but not limited to, mechanical, electronic or communications failure or degradation, or
        any force majeure event.
      </p>
      <p className={contentClass}>
        (iii) The TOU is an agreement between separate legal entities and neither party is the agent
        or employee of the other for any purpose whatsoever. The parties do not intend to
        create a partnership or joint venture between themselves. Neither party shall have the
        right to bind the other to any agreement or to incur any obligation or liability on behalf
        of the other party, except as provided for in these TOU, or as otherwise permitted by
        the functioning of the Services.
      </p>
      <p className={contentClass}>
        (iv) If any provision of the TOU is found by a court of competent jurisdiction to be invalid,
        the parties nevertheless agree that the court should endeavour to give effect to the
        parties’ intentions as reflected in the provision, and the other provisions of the TOU
        shall remain in full force and effect.
      </p>
      <p className={contentClass}>
        (v) The failure of Slice to exercise or enforce any right or provision of these TOU does not
        constitute a waiver of such right or provision, which will still be available to Slice.
      </p>
      <p className={contentClassLast}>
        (vi) The section titles in the TOU are for convenience only and have no legal or contractual
        effect.
      </p>
      {/** Copyright */}
      <p className={contentClassLast}>&copy; Slice Insurance Technologies Inc. 2024</p>
    </div>
  </Layout>
)

export default TermsOfUsePage
